.about {
  &_president {
    @media (--md) {
      display: flex;
      justify-content: space-between;
    }

    &_img {
      width: 200px;
      margin: 0 auto 20px;
      text-align: center;
      font-size: 14px;

      @media (--md) {
        width: 220px;
        margin: 0;
      }
    }

    &_text {
      font-family: $font-serif;
      font-size: 14px;
      letter-spacing: -.01em;
      line-height: 1.9;

      @media (--md) {
        width: calc(100% - 260px);
        font-size: 15px;
        line-height: 2.2;
      }
    }
  }

  &_company {
    &_tbody {
      > tr {
        @media (--md_max) {
          display: block;
          margin-bottom: 14px;
        }

        > th {
          @media (--md_max) {
            display: block;
            padding-bottom: 5px;
            font-size: 12px;
            border-bottom: 1px solid #ccc;
          }

          @media (--md) {
            width: 160px;
            text-align: right;
            font-size: 14px;
          }
        }

        > td {
          @media (--md_max) {
            display: block;
            padding-top: 5px;
            font-size: 12px;
          }

          @media (--md) {
            font-size: 14px;
            line-height: 1.8;
          }
        }
      }
    }
  }

  &_archive {
    @media (--md) {
      margin-top: 40px;
    }

    &_item {
      @media (--md_max) {
        width: 100% !important;
        margin-bottom: 30px;
      }
    }

    &_title {
      margin-bottom: 10px;
      padding-bottom: 10px;
      text-align: center;
      font-size: 16px;
      border-bottom: 1px solid $color-black;
    }

    &_list {
      li {
        line-height: 1.8;
      }
    }
  }
}
