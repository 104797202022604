.l-header {
  @media (--md_max) {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 100%;
    background-color: rgb(0 0 0 / 90%);
    transition: .24s ease;
    z-index: 1;
  }

  &_inner {
    @media (--md_max) {
      pointer-events: none;
    }

    h1 {
      width: 180px;
      margin: 0 auto;
      padding: 10px 15px 10px;

      @media (--md) {
        width: 200px;
        margin: 0 0 0 auto;
        padding: 15px 15px 15px 0;
      }
    }

    ul {
      @media (--md) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0 25px;
      }

      li {
        text-align: center;

        @media (--md) {
          width: 120px;
        }

        &.is-active {
          a {
            color: $color-red;
          }
        }

        &:nth-child(1) {
          width: 40px;
          margin: 0 auto;
          padding-top: 40px;

          @media (--md) {
            display: none;
          }
        }

        &:nth-child(4) {
          @media (--md_max) {
            display: none;
          }

          a {
            width: 40px;
            margin: 0 auto;
            font-size: 0;
          }
        }

        a {
          display: block;
          padding: 15px 0;
          color: $color-white;
          text-align: center;
          font-size: 17px;
          font-weight: bold;

          @media (--md) {
            padding: 0;
            color: $color-black;
            font-size: 14px;
            border-bottom: none;

            &:hover {
              color: $color-red;
            }
          }

          small {
            display: block;
            letter-spacing: -.01em;
            font-size: 12px;

            @media (--md) {
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  &_humberger {
    position: fixed;
    width: 50px;
    height: 50px;
    top: 18px;
    right: 15px;
    background-color: rgb(0 0 0 / 80%);
    z-index: 10;

    @media (--md) {
      display: none;
    }

    span {
      position: absolute;
      height: 2px;
      width: 24px;
      left: 12px;
      background-color: $color-white;
      transition: .5s ease;

      &:nth-child(1) {
        top: 15px;
      }

      &:nth-child(2) {
        top: 23px;
      }

      &:nth-child(3) {
        top: 31px;
      }
    }

    &.js-active {
      background-color: rgb(0 0 0 / 0%);

      span {
        &:nth-child(1) {
          transform: rotate(45deg) translate(7px, 9px);
        }

        &:nth-child(2) {
          opacity: 0;
          transform: translate(10px, 4px);
        }

        &:nth-child(3) {
          transform: rotate(-45deg) translate(3px, -4px);
        }
      }
    }
  }

  &.js-active {
    left: 0;

    h1 {
      display: none;
    }

    .l-header_inner {
      pointer-events: all;
    }
  }
}

.l-footer {
  position: relative;

  .copyright {
    padding: 14px;
    font-size: 10px;
    text-align: center;
    background-color: $color-black;
    color: $color-white;

    @media (--md) {
      font-size: 12px;
    }
  }
}

.l-overlay {
  position: fixed;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  &.js-active {
    pointer-events: all;
  }
}

.l-contents {
  margin: 0 0 40px;

  @media (--md) {
    max-width: 1000px;
    margin: 0 auto 60px;
  }
}

.l-section {
  &_inner {
    padding: 0 15px;

    section {
      margin-bottom: 30px;

      @media (--md) {
        margin-bottom: 50px;
      }
    }
  }

  &_container {
    padding: 0 15px;

    @media (--md) {
      padding: 0 30px;
    }

    @media (--lg) {
      padding: 0;
    }

    section {
      margin-bottom: 30px;

      @media (--md) {
        margin-bottom: 50px;
      }
    }
  }
}

.l-title {
  margin: 0 auto 40px;
  background-color: $color-black;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  h1 {
    padding: 60px 0;
    text-align: center;
    font-weight: bold;
    color: $color-white;
    font-size: 20px;
    letter-spacing: .13em;
    text-shadow: 0 0 16px rgba(0, 0, 0, 0.81);

    @media (--md) {
      padding: 140px 0;
      font-size: 24px;
    }
  }

  &.services {
    background-image: url('../images/services/bg_title.jpg');
  }

  &.cases {
    background-image: url('../images/cases/bg_title.jpg');
  }

  &.about {
    background-image: url('../images/about/bg_title.jpg');
  }

  &.contact {
    background-image: url('../images/contact/bg_title.jpg');
  }
}

.l-column2 {
  @media (--md) {
    display: flex;
    justify-content: space-between;

    > section {
      width: 49%;
    }
  }
}

.l-column3 {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @media (--md) {
    &::after {
      content: "";
      display: block;
      width: calc((100% - 5rem) / 3);
      margin-right: 1rem;
    }
  }

  > div {
    width: 48%;

    @media (--md) {
      width: 30.6%;
    }
  }
}

.l-column4 {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @media (--md) {
    &::after {
      content: "";
      display: block;
      width: calc((100% - 3rem) / 2);
      margin-right: 1rem;
    }
  }

  > div {
    width: 48%;

    @media (--md) {
      width: 23.2%;
    }
  }
}

.l-contact {
  @include fz(14);

  width: 100%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;

  &_tel {
    a {
      color: $color-black;

      @media (--md) {
        pointer-events: none;
      }
    }
  }

  &_mail {
    margin-left: 10px;

    a {
      display: block;
    }
  }
}
