.u-sm {
  display: none;

  @include media(sm) {
    display: block;
  }
}

.u-md {
  display: none;

  @include media(md) {
    display: block;
  }
}

.u-lg {
  display: none;

  @include media(lg) {
    display: block;
  }
}

.u-xl {
  display: none;

  @include media(xl) {
    display: block;
  }
}

.u-sm_max {
  display: none;

  @include media(sm_max) {
    display: block;
  }
}

.u-md_max {
  display: none;

  @include media(md_max) {
    display: block;
  }
}

.u-lg_max {
  display: none;

  @include media(lg_max) {
    display: block;
  }
}

.u-xl_max {
  display: none;

  @include media(xl_max) {
    display: block;
  }
}

p {
  &.u-es {
    font-size: 10px;

    @include media(lg) {
      font-size: 12px;
    }
  }

  &.u-s {
    font-size: 12px;

    @include media(lg) {
      font-size: 14px;
    }
  }

  &.u-m {
    font-size: 13px;
    line-height: 1.8;

    @include media(lg) {
      font-size: 15px;
    }
  }

  &.u-l {
    font-size: 16px;
  }

  &.u-xl {
    font-size: 18px;
  }
}
