.top {
  position: relative;

  .l-header_inner {
    nav {
      @media (--md) {
        display: none;
      }
    }
  }

  .logo {
    padding: 10px 15px;

    @media (--md) {
      margin-top: 0;
    }

    h1 {
      width: 180px;
      margin: 0 auto;

      @media (--md) {
        width: 200px;
        margin: 0 0 0 auto;
      }
    }
  }

  .mv {
    @media (--md) {
      display: flex;
      align-items: center;
    }

    &_menu {
      display: none;
      position: relative;
      margin: 40px auto 20px;
      text-align: center;

      @media (--md) {
        display: block;
        width: 16%;
        margin: 0;
      }

      &_logo {
        cursor: pointer;
        width: 40px;
        margin: 0 auto;

        @media (--md) {
          margin: 0 35px 0 auto;

          &:hover {
            span {
              color: $color-red;
            }
          }
        }

        span {
          @include fz(13);

          font-weight: bold;

          &.is-open {
            display: none;
            color: $color-red;
          }
        }

        &.js-active {
          span {
            &.is-close {
              display: none;
            }

            &.is-open {
              display: block;
            }
          }
        }
      }

      &_gnav {
        display: none;
        pointer-events: none;
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        top: 0;
        left: 16%;

        @media (--md) {
          display: block;
        }

        nav {
          position: absolute;
          left: -290px;
          width: 290px;
          height: 100%;
          padding: 50px;
          background-color: rgb(0 0 0 / 80%);
          z-index: 3;
          text-align: left;
          transition: .24s ease;

          ul {
            li {
              margin-bottom: 20px;

              a {
                color: $color-white;
                font-size: 16px;

                @media (--md) {
                  &:hover {
                    color: $color-red;
                  }
                }
              }
            }
          }
        }

        &.js-active {
          pointer-events: all;

          nav {
            left: 0;
          }
        }
      }
    }

    &_image {
      position: relative;
      width: 100%;
      font-size: 0;

      @media (--md) {
        width: 84%;
      }

      span {
        position: absolute;
        padding: 20px;
        background-color: rgb(0 0 0 / 50%);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (--md) {
          padding: 25px 42px;
          left: 40%;
        }

        img {
          width: 210px;
          margin: 0 auto;
        }
      }
    }
  }

  .contents {
    display: flex;
    flex-flow: row wrap;

    @media (--md) {
      // max-width: 1200px;
      margin: 0 auto;
    }
  }
}
