// color
$color-black: #000;
$color-white: #fff;
$color-grey: #777;
$color-grey-light: #f1f1f1;
$color-main: $color-black;
$color-main-light: #0071bb;
$color-red: #c60000;

@import url('https://fonts.googleapis.com/earlyaccess/hannari.css');
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Shippori+Mincho:wght@400;600&display=swap');

// font-family
$font-sans: 'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'メイリオ', sans-serif;
$font-serif: 'Hannari', 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
$font-en: 'Lato', $font-sans;

//bp min
@custom-media --s (min-width: 340px);
@custom-media --xs (min-width: 425px);
@custom-media --sm (min-width: 554px);
@custom-media --md (min-width: 768px);
@custom-media --lg (min-width: 992px);
@custom-media --xl (min-width: 1200px);

//bp max
@custom-media --s_max (max-width: 341px);
@custom-media --xs_max (max-width: 424px);
@custom-media --sm_max (max-width: 553px);
@custom-media --md_max (max-width: 767px);
@custom-media --lg_max (max-width: 991px);
@custom-media --xl_max (max-width: 1199px);

$breakpoints:(
  's': 'screen and (min-width: 340px)',
  'xs': 'screen and (min-width: 425px)',
  'sm': 'screen and (min-width: 554px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 992px)',
  'xl': 'screen and (min-width: 1200px)',
  's_max': 'screen and (max-width: 341px)',
  'xs_max': 'screen and (max-width: 424px)',
  'sm_max': 'screen and (max-width: 553px)',
  'md_max': 'screen and (max-width: 767px)',
  'lg_max': 'screen and (max-width: 991px)',
  'xl_max': 'screen and (max-width: 1199px)',
) !default;

@mixin media($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

//fz
@function get_vw($size, $viewport: 640) {
  $rate: 100 / $viewport;

  @return $rate * $size * 1vw;
}

@mixin fz($font_size: 10) {
  font-size: get_vw($font_size);

  @include media(sm) {
    font-size: $font_size - 2px;
  }

  @include media(md) {
    font-size: $font_size * 1px;
  }
}

//font-justify
%justify {
  text-align: justify;
  text-justify: inter-ideograph;
}

%mdimg {
  img {
    @media (--md) {
      width: 100%;
    }
  }
}
