.m-btn {
  position: relative;
  margin-top: 30px;

  a {
    display: inline-block;
    padding: 4px 10px;
    border-bottom: 1px solid $color-black;
    font-size: 12px;
    line-height: 1;

    @media (--md) {
      padding: 10px;
      font-size: 13px;

      &:hover {
        color: $color-red;
      }
    }
  }
}

.m-h2 {
  margin-bottom: 20px;
  padding: 8px 0;
  background-color: $color-black;
  color: $color-white;
  font-size: 14px;
  text-align: center;

  @media (--md) {
    padding: 5px 0;
    font-size: 16px;
  }
}

.m-text {
  &_normal {
    font-size: 12px;
    line-height: 1.5;

    @media (--md) {
      font-size: 14px;
    }
  }

  &_cl_red {
    color: $color-red;
  }
}
