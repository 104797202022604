html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-size: 14px;
  line-height: 1.6;
  height: 100%;
  width: 100%;
  color: $color-black;
  text-align: left;
  margin: 0;
  padding: 0;
  font-family: $font-sans;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  overflow-y: scroll;
  letter-spacing: .1em;

  &.js-fix {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  color: $color-black;
  transition: .24s ease all;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  font-size: 0;

  @media (--sm_max) {
    width: 100%;
  }
}
