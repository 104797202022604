.services {
  &_card {
    margin-bottom: 15px;

    &_img {
      text-align: center;
      font-size: 0;
      margin-bottom: 4px;
    }

    &_title {
      margin: 10px 0 4px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
    }

    &_text {
      font-size: 12px;
    }
  }
}
