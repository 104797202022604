.contact {
  &_form {
    margin-top: 15px;

    &_item {
      margin: 20px 0;

      @media (--md) {
        display: flex;
        align-items: stretch;

        &.is-data {
          label {
            padding: 74px 0;
          }
        }
      }

      label {
        display: block;
        padding: 6px;
        font-size: 13px;
        background-color: #dcdcdc;

        @media (--md) {
          display: flex;
          align-items: center;
          width: 160px;
          // height: 100%;
          min-height: 100%;
          margin-right: 10px;
        }
      }

      input {
        margin-top: 5px;
        width: 100%;
        min-height: 30px;

        @media (--md) {
          margin-top: 0;
          width: auto;
        }
      }

      textarea {
        margin-top: 5px;
        width: 100%;

        @media (--md) {
          margin-top: 0;
          min-height: 170px;
        }
      }
    }

    &_input {
      @media (--md) {
        width: calc(100% - 170px);

        [size="10"] {
          width: 100%;
        }
      }
    }

    &_btn {
      text-align: center;

      &_back {
        appearance: auto;
        user-select: none;
        white-space: pre;
        align-items: flex-start;
        text-align: center;
        cursor: default;
        box-sizing: border-box;
        padding: 1px 6px;
        border-width: 2px;
        border-style: outset;
        background-color: #efefef;
        border-image: initial;
        border: 1px solid #767676;
        border-radius: 3px;
        font-size: 14px;
      }
    }
  }

  &_privacy {
    &_item {
      margin-bottom: 15px;
    }
  }
}
