.cases {
  &_card {
    margin-bottom: 15px;
    text-align: center;

    &_img {
      position: relative;
      display: block;
      text-align: center;
      font-size: 0;
      margin-bottom: 4px;
      overflow: hidden;
      background-color: $color-black;

      div {
        position: absolute;
        width: 100%;
        bottom: 6px;
        background-color: rgb(0 0 0 / 60%);
        text-align: center;
        transition: .24s ease;

        span {
          display: block;
          padding: 6px 0;
          line-height: 1;
          color: $color-white;
          font-size: 11px;

          &::after {
            content: '';
            display: inline-block;
            margin-left: 3px;
            vertical-align: middle;
            width: 10px;
            height: 10px;
            background: url('../images/common/icon_arrow_wh.svg') no-repeat center/contain;
            transition: .24s ease;
          }

          @media (--md) {
            padding: 8px 0 10px;
            font-size: 12px;
          }
        }
      }

      img {
        width: 100%;
        transition: .24s ease;
      }

      &:hover {
        @media (--md) {
          div {
            background-color: $color-black;

            span {
              &::after {
                transform: translate(10px, 0);
              }
            }
          }

          img {
            transform: scale(1.06);
            opacity: .7;
          }
        }
      }
    }

    &_title {
      margin: 10px 0 4px;
      text-align: center;
      font-size: 13px;
      font-weight: bold;

      @media (--md) {
        font-size: 14px;
      }
    }

    &_text {
      font-size: 11px;
      text-align: left;

      @media (--md) {
        font-size: 12px;
      }
    }
  }

  &_modal {
    &_img {
      margin-bottom: 30px;

      &:last-child {
        padding-bottom: 60px;

        @media (--md) {
          padding-bottom: 100px;
        }
      }
    }
  }
}

//lity modal
.lity {
  &-container {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    padding-top: 30px;

    @media (--md) {
      padding-top: 60px;
      max-width: 600px;
    }

    .lity-content {
      width: 86%;
      margin: 0 auto;
      text-align: center;

      @media (--md) {
        width: auto;
      }
    }

    &::-webkit-scrollbar {  /* Chrome, Safari 対応 */
      display: none;
    }
  }
}
